import { colors, fillColors } from "./colors";

export function renderLabels(nodes, svg) {
  return svg
    .append("g")
    .selectAll("text")
    .data(nodes)
    .join("text")
    .attr("x", (d) => d.x)
    .attr("y", (d) => d.y)
    .attr("dx", -16)
    .attr("dy", 4)
    .attr("font-size", 12)
    .attr("font-weight", 700)
    .attr("fill", "white")
    .text((d) => d.data.projectId);
}

export function renderLines(links, svg) {
  return svg
    .append("g")
    .selectAll(".line")
    .data(links)
    .enter()
    .append("line")
    .attr("x1", function (d) {
      return d.source.x;
    })
    .attr("y1", function (d) {
      return d.source.y;
    })
    .attr("x2", function (d) {
      return d.target.x;
    })
    .attr("y2", function (d) {
      return d.target.y;
    })
    .style("stroke", "rgba(0, 0, 0, 0.2)");
}

export function renderSVGNodes(nodes, svg, showLabels) {
  return svg
    .append("g")
    .selectAll(".complex")
    .data(nodes)
    .join("g")
    .attr("class", "complex")
    .attr("transform", (d) => `translate(${d.x},${d.y})`)
    .attr("x", (d) => d.x)
    .attr("y", (d) => d.y)
    .call(renderNodes(showLabels));
}

const renderNodes = (showLabels) => (chartNodes) => {
  // left arc
  chartNodes
    .append("path")
    .attr("d", (node) => {
      const { r = 20 } = node;
      return `M0,-${r} a ${r} ${r}  -45 1 0 -0,${2 * r}`;
    })
    .attr("stroke", (node) =>
      node.data.center ? "#999" : colors[node.data.status][0]
    )
    .attr("stroke-width", 3)
    .attr("fill", (node) =>
      node.data.center ? "#999" : fillColors[node.data.status][0]
    );

  // right arc
  chartNodes
    .append("path")
    .attr("d", (node) => {
      const { r = 20 } = node;
      return `M0,-${r} a ${r} ${r}  -45 0 1 -0,${2 * r}`;
    })
    .attr("stroke", (node) =>
      node.data.center ? "#999" : colors[node.data.status][1]
    )
    .attr("stroke-width", 3)
    .attr("fill", (node) =>
      node.data.status ? fillColors[node.data.status][1] : "#999"
    );

  // node label
  if (showLabels) {
    chartNodes
      .append("text")
      .attr("dx", -16)
      .attr("dy", 4)
      .attr("font-size", 12)
      .attr("font-weight", 700)
      .attr("fill", "white")
      .text((node) => node.data.projectId);
  }

  // red circle to identify center of group
  // chartNodes
  //   .append("circle")
  //   .attr("cx", 0)
  //   .attr("cy", 0)
  //   .attr("r", 2)
  //   .attr("fill", "red");
};
