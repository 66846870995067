import generateName from "project-name-generator";

// console.log(generateName().dashed);

const STATUS = ["GREEN", "AMBER_GREEN", "AMBER", "RED_AMBER", "RED"];

const generateNode = (group) => (_, index) => ({
  group,
  projectId: generateName().dashed,
  status: STATUS[Math.floor((Math.cos(index) + 1) * 2.4)],
  value: Math.ceil(Math.sin(index) + 1.5),
});

export const getChartData = (extraNodes) => ({
  children: [
    {
      children: [
        { group: 2, status: "", value: 3.0, center: true },
        { group: 2, status: "AMBER", value: 1.0, projectId: "#101" },
        { group: 2, status: "RED", value: 1.0, projectId: "#102" },
        { group: 2, status: "AMBER_GREEN", value: 1.0, projectId: "#103" },
        { group: 2, status: "RED", value: 1.0, projectId: "#104" },
        { group: 2, status: "GREEN", value: 1.0, projectId: "#105" },
        { group: 2, status: "AMBER", value: 1.0, projectId: "#106" },
        { group: 2, status: "RED_AMBER", value: 1.0, projectId: "#107" },
        { group: 2, status: "GREEN", value: 2.0, projectId: "#108" },
        { group: 2, status: "RED_AMBER", value: 3.0, projectId: "#109" },
        ...new Array(extraNodes).fill(1).map(generateNode(2)),
      ],
    },
    {
      children: [
        { group: 0, status: "", value: 3.0, center: true },
        { group: 0, status: "RED_AMBER", value: 1.0, projectId: "#201" },
        { group: 0, status: "GREEN", value: 1.0, projectId: "#202" },
        { group: 0, status: "AMBER", value: 1.0, projectId: "#203" },
        { group: 0, status: "RED", value: 1.0, projectId: "#204" },
        { group: 0, status: "AMBER_GREEN", value: 1.0, projectId: "#205" },
        { group: 0, status: "GREEN", value: 1.0, projectId: "#206" },
        { group: 0, status: "AMBER", value: 2.0, projectId: "#207" },
        { group: 0, status: "RED", value: 3.0, projectId: "#208" },
        { group: 0, status: "GREEN", value: 3.0, projectId: "#209" },
        ...new Array(extraNodes).fill(1).map(generateNode(0)),
      ],
    },
    {
      children: [
        { group: 1, status: "", value: 3.0, center: true },
        { group: 1, status: "GREEN", value: 1.0, projectId: "#301" },
        { group: 1, status: "AMBER_GREEN", value: 1.0, projectId: "#302" },
        { group: 1, status: "AMBER", value: 2.0, projectId: "#303" },
        { group: 1, status: "RED", value: 3.0, projectId: "#304" },
        { group: 1, status: "RED_AMBER", value: 3.0, projectId: "#305" },
        { group: 1, status: "RED", value: 1.0, projectId: "#306" },
        { group: 1, status: "AMBER", value: 2.0, projectId: "#307" },
        { group: 1, status: "GREEN", value: 3.0, projectId: "#308" },
        { group: 1, status: "GREEN", value: 3.0, projectId: "#309" },
        ...new Array(extraNodes).fill(1).map(generateNode(1)),
      ],
    },
    {
      children: [
        { group: 4, status: "", value: 3.0, center: true },
        { group: 4, status: "AMBER_GREEN", value: 1.0, projectId: "#401" },
        { group: 4, status: "GREEN", value: 1.0, projectId: "#402" },
        { group: 4, status: "AMBER", value: 2.0, projectId: "#403" },
        { group: 4, status: "RED_AMBER", value: 3.0, projectId: "#404" },
        { group: 4, status: "RED", value: 3.0, projectId: "#405" },
        { group: 4, status: "GREEN", value: 1.0, projectId: "#406" },
        { group: 4, status: "AMBER", value: 2.0, projectId: "#407" },
        { group: 4, status: "RED", value: 3.0, projectId: "#408" },
        { group: 4, status: "RED_AMBER", value: 3.0, projectId: "#409" },
        ...new Array(extraNodes).fill(1).map(generateNode(4)),
      ],
    },
  ],
});
