export function generateLinks(nodes) {
  const nodesMap = nodes.reduce((map, node) => {
    if (node.data?.projectId) {
      map.set(node.data.projectId, node);
    }

    return map;
  }, new Map());

  return [
    // every 01 depends on 03, 05, 07, 09
    { source: nodesMap.get("#101"), target: nodesMap.get("#103") },
    { source: nodesMap.get("#101"), target: nodesMap.get("#105") },
    { source: nodesMap.get("#101"), target: nodesMap.get("#107") },
    { source: nodesMap.get("#101"), target: nodesMap.get("#109") },
    // every 03 depends on 02, 04, 06
    { source: nodesMap.get("#103"), target: nodesMap.get("#102") },
    { source: nodesMap.get("#103"), target: nodesMap.get("#104") },
    // every 04 depends on 06, 08
    { source: nodesMap.get("#104"), target: nodesMap.get("#106") },
    { source: nodesMap.get("#104"), target: nodesMap.get("#108") },

    { source: nodesMap.get("#201"), target: nodesMap.get("#203") },
    { source: nodesMap.get("#201"), target: nodesMap.get("#205") },
    { source: nodesMap.get("#201"), target: nodesMap.get("#207") },
    { source: nodesMap.get("#201"), target: nodesMap.get("#209") },
    { source: nodesMap.get("#203"), target: nodesMap.get("#202") },
    { source: nodesMap.get("#203"), target: nodesMap.get("#204") },
    { source: nodesMap.get("#204"), target: nodesMap.get("#206") },
    { source: nodesMap.get("#204"), target: nodesMap.get("#208") },

    { source: nodesMap.get("#301"), target: nodesMap.get("#303") },
    { source: nodesMap.get("#301"), target: nodesMap.get("#305") },
    { source: nodesMap.get("#301"), target: nodesMap.get("#307") },
    { source: nodesMap.get("#301"), target: nodesMap.get("#309") },
    { source: nodesMap.get("#303"), target: nodesMap.get("#302") },
    { source: nodesMap.get("#303"), target: nodesMap.get("#304") },
    { source: nodesMap.get("#304"), target: nodesMap.get("#306") },
    { source: nodesMap.get("#304"), target: nodesMap.get("#308") },

    { source: nodesMap.get("#401"), target: nodesMap.get("#403") },
    { source: nodesMap.get("#401"), target: nodesMap.get("#405") },
    { source: nodesMap.get("#401"), target: nodesMap.get("#407") },
    { source: nodesMap.get("#401"), target: nodesMap.get("#409") },
    { source: nodesMap.get("#403"), target: nodesMap.get("#402") },
    { source: nodesMap.get("#403"), target: nodesMap.get("#404") },
    { source: nodesMap.get("#404"), target: nodesMap.get("#406") },
    { source: nodesMap.get("#404"), target: nodesMap.get("#408") },
  ];
}
