// @ts-nocheck
import React from "react";
import * as d3 from "d3";
import graphlib from "graphlib";

import { addFillPatterns } from "./colors";
import { forceCluster, forceCollide } from "./force";
import { generateLinks } from "./links";
import { nodeDragHandler } from "./eventHandlers";
import { renderLines, renderSVGNodes } from "./render";

const pack = (data, width, height) => {
  return d3.pack().size([width, height]).padding(1)(
    d3.hierarchy(data).sum((d) => d.value)
  );
};

export class NetworkChart extends React.Component {
  static defaultProps = {
    height: 600,
    width: 900,
  };

  constructor(props) {
    super(props);
    this.data = props.data;
    this.id = `dependencyChart_${Math.random()}`.replace(".", "");

    this.dependencies = new graphlib.Graph();
    this.nodes = [];
    this.links = [];
    this.svg = null;
  }

  componentDidMount() {
    const { height, width } = this.props;

    this.nodes = pack(this.data, width, height).leaves();
    this.nodes.forEach((node) => {
      if (node.data.projectId) {
        this.dependencies.setNode(node.data.projectId);
      }
    });

    this.links = generateLinks(this.nodes);
    this.links.forEach((link) => {
      const { source, target } = link;
      this.dependencies.setEdge(source.data.projectId, target.data.projectId);
    });

    this.svg = d3
      .select(`#${this.id}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .on("click", this.deselect);

    addFillPatterns(this.svg);

    this.redraw();
  }

  deselect = () => {
    this.nodes.forEach((d) => (d.selected = true));
    this.updateNodes();
  };

  getNearbyDependencies = (nodeName, level = 1) => {
    const successors = this.dependencies.successors(nodeName);
    const nextLevel =
      level > 3
        ? []
        : successors.map((name) => this.getNearbyDependencies(name, level + 1));

    return [...successors, ...nextLevel].flat();
  };

  onClick = (node, i) => {
    d3.event.stopPropagation();
    const { projectId } = node.data;

    if (!projectId) {
      this.deselect();
      return;
    }

    const nearbyNodes = [projectId, ...this.getNearbyDependencies(projectId)];
    this.nodes.forEach(
      (d) => (d.selected = nearbyNodes.includes(d.data.projectId))
    );

    this.updateNodes();
  };

  redraw() {
    const simulation = d3
      .forceSimulation(this.nodes)
      .force("cluster", forceCluster())
      .force("collide", forceCollide());

    const lines = renderLines(this.links, this.svg);
    this.svgNodes = renderSVGNodes(
      this.nodes,
      this.svg,
      this.nodes.length < 100
    )
      .call(nodeDragHandler(simulation))
      .on("click", this.onClick);

    // update node and line positions
    simulation.on("tick", () => {
      this.svgNodes.attr("transform", (d) => `translate(${d.x},${d.y})`);

      lines
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y);
    });
  }

  updateNodes = () => {
    this.svgNodes.attr("opacity", (d) => (d.selected ? 1.0 : 0.3));
  };

  render() {
    const { height, style, width } = this.props;

    return <div id={this.id} style={{ ...style, height, width }} />;
  }
}
