import * as d3 from "d3";

export function forceCluster() {
  const strength = 0.2;
  let nodes;

  function force(alpha) {
    const centroids = new Map();
    centroids.set(0, { x: 150, y: 150 });
    centroids.set(1, { x: 750, y: 150 });
    centroids.set(2, { x: 450, y: 300 });
    centroids.set(3, { x: 150, y: 450 });
    centroids.set(4, { x: 750, y: 450 });

    const l = alpha * strength;
    for (const d of nodes) {
      const _cent = centroids.get(d.data.group);
      const { x: cx, y: cy } = _cent;

      if (d.data.center) {
        d.vx = 0;
        d.vy = 0;
        d.y = cy;
        d.x = cx;
      } else {
        d.vx -= (d.x - cx) * l;
        d.vy -= (d.y - cy) * l;
      }
    }
  }

  force.initialize = (_) => (nodes = _);

  return force;
}

export function forceCollide() {
  const alpha = 0.2; // fixed for greater rigidity!
  let nodes;
  const maxRadius = 30;
  const padding = 10;

  function force() {
    const quadtree = d3.quadtree(
      nodes,
      // @ts-ignore
      (d) => d.x,
      // @ts-ignore
      (d) => d.y
    );

    for (const d of nodes) {
      const r = d.r + maxRadius;
      const nx1 = d.x - r;
      const ny1 = d.y - r;
      const nx2 = d.x + r;
      const ny2 = d.y + r;

      quadtree.visit((q, x1, y1, x2, y2) => {
        if (!q.length)
          do {
            // @ts-ignore
            if (q.data !== d) {
              // const padding =
              //  d.data.group === q.data.data.group ? padding1 : padding2;

              // @ts-ignore
              const r = d.r + q.data.r + padding;

              // @ts-ignore
              let x = d.x - q.data.x;
              // @ts-ignore
              let y = d.y - q.data.y;
              let l = Math.hypot(x, y);

              if (l < r) {
                l = ((l - r) / l) * alpha;
                d.x -= x *= l;
                d.y -= y *= l;
                // @ts-ignore
                q.data.x += x;
                // @ts-ignore
                q.data.y += y;
              }
            }
            // @ts-ignore
          } while ((q = q.next));

        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1;
      });
    }
  }

  force.initialize = (_) => {
    nodes = _;
  };

  return force;
}
