import React from "react";
import "./App.css";

import { NetworkChart } from "./components/NetworkChart/NetworkChart";
import { getChartData } from "./components/NetworkChart/data";

const getOption = (value) => {
  return (
    <option key={value} value={value}>
      {value}
    </option>
  );
};

const App = () => {
  const [extraNodes, setExtraNodes] = React.useState(5);

  const centered = {
    width: 900,
    margin: "30px auto",
  };

  return (
    <div style={centered}>
      <h1>Dependency Network Chart</h1>
      <p>
        Each group of nodes contains one dependency tree (nodes 01-09). Click a
        node to highlight the nodes and its dependencies (<em>depends on</em>{" "}
        only). Click the background to deselect. Add additional nodes without
        dependencies to test the layout performance.
      </p>

      <label htmlFor="extra-nodes">Select number of extra nodes:</label>

      <select
        id="extra-nodes"
        onChange={(evt) => {
          console.log(evt.target.value);
          setExtraNodes(parseInt(evt.target.value, 10));
        }}
        style={{ marginLeft: 10 }}
        value={extraNodes}
      >
        {[0, 5, 10, 25, 50, 100, 500].map(getOption)}
      </select>

      <NetworkChart
        key={extraNodes}
        data={getChartData(extraNodes)}
        style={{ backgroundColor: "#eee", marginTop: 20 }}
      />
    </div>
  );
};

export default App;
