const addCircle = (pattern, cx, cy) => {
  pattern
    .append("circle")
    .attr("cx", cx)
    .attr("cy", cy)
    .attr("r", 3)
    .attr("style", "stroke: none; fill: #56945C");
};

export const addFillPatterns = (svg) => {
  const defs = svg.append("defs");

  addRedFillPattern(defs);
  addGreenFillPattern(defs);
  addYellowFillPattern(defs);

  return defs;
};

const addRect = (pattern, xy, size, color) => {
  pattern
    .append("rect")
    .attr("x", xy)
    .attr("y", xy)
    .attr("width", size)
    .attr("height", size)
    .attr("style", `stroke: none; fill: ${color}`);
};

export const addGreenFillPattern = (defs) => {
  const greenPattern = defs
    .append("pattern")
    .attr("id", "fill-green")
    .attr("height", 24)
    .attr("width", 24)
    .attr("patternUnits", "userSpaceOnUse");

  addRect(greenPattern, 0, 24, "#6FBD76");
  addCircle(greenPattern, 12, 12);
  addCircle(greenPattern, 0, 0);
  addCircle(greenPattern, 0, 24);
  addCircle(greenPattern, 24, 0);
  addCircle(greenPattern, 24, 24);
};

export const addRedFillPattern = (defs) => {
  const redPattern = defs
    .append("pattern")
    .attr("id", "fill-red")
    .attr("height", 24)
    .attr("width", 24)
    .attr("patternUnits", "userSpaceOnUse");

  addRect(redPattern, 0, 24, "#DF625E");
  addRect(redPattern, 12, 12, "#B04B48");
  addRect(redPattern, 0, 12, "#B04B48");
};

export const addYellowFillPattern = (defs) => {
  const yellowPattern = defs
    .append("pattern")
    .attr("id", "fill-yellow")
    .attr("height", 17)
    .attr("width", 17)
    .attr("patternUnits", "userSpaceOnUse");

  addRect(yellowPattern, 0, 17, "#F7C86D");
  const group = yellowPattern
    .append("g")
    .attr("transform", "translate(8.5, 8.5), rotate(45)");
  addRect(group, -6, 12, "#EBB464");
};

export const colors = {
  AMBER: ["#FFC75B", "#FFC75B"],
  AMBER_GREEN: ["#4FC06E", "#FFC75B"],
  GREEN: ["#4FC06E", "#4FC06E"],
  RED: ["#F05858", "#F05858"],
  RED_AMBER: ["#F05858", "#FFC75B"],
};

export const fillColors = {
  AMBER: ["url('#fill-yellow')", "url('#fill-yellow')"],
  AMBER_GREEN: ["url('#fill-green')", "url('#fill-yellow')"],
  GREEN: ["url('#fill-green')", "url('#fill-green')"],
  RED: ["url('#fill-red')", "url('#fill-red')"],
  RED_AMBER: ["url('#fill-red')", "url('#fill-yellow')"],
};
