import * as d3 from "d3";

export function nodeDragHandler(simulation) {
  function dragStarted(d) {
    console.log("drag start");
    // @ts-ignore
    if (!d3.event.active) simulation.alphaTarget(0.1).restart();
    d.fx = d.x;
    d.fy = d.y;
  }

  function dragged(d) {
    // @ts-ignore
    d.fx = d3.event.x;
    // @ts-ignore
    d.fy = d3.event.y;
  }

  function dragEnded(d) {
    console.log("drag end");
    // @ts-ignore
    if (!d3.event.active) simulation.alphaTarget(0);
    d.fx = null;
    d.fy = null;
  }

  return d3
    .drag()
    .on("start", dragStarted)
    .on("drag", dragged)
    .on("end", dragEnded);
}
